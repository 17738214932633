@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,400&display=swap);
* {
  margin: 0;
  padding: 0;
  border: none;
  max-width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
}
